import axios from "axios";

import { ConsoleLogs } from "../../utils/ConsoleLogs";
import { alertErrorMessage } from "../../Components/CustomAlertMessage";

const TAG = "ApiCall";

export const ApiCallPost = async (url, parameters, headers) => {
  try {
    const response = await axios.post(url, parameters, { headers: headers });
    ConsoleLogs(
      TAG + ", ApiCallPost",
      `apiDebug,response: ${JSON.stringify(response.data)}`
    );
    return response.data;
  }
  catch (error) {
    ConsoleLogs(
      TAG + ",ApiCallPost",
      `apiDebug, response error: ${JSON.stringify(error.response.data.message)}`
    );
    return error.response.data;
  }
};
export const ApiCallDelete = async (url, parameters, headers) => {
  try {
    const response = await axios.delete(url, parameters, { headers: headers });
    return response.data;
  } catch (error) {
    if (error.response.data.message === "Token is expired with message: res is not defined") {
      alertErrorMessage('Token is Expired');
      sessionStorage.clear();
      window.location.reload();
      return;
    }
    return error.response.data;
  }
};

export const ApiCallGet = async (url, headers) => {
  try {
    const response = await axios.get(url, { headers: headers });
    ConsoleLogs(
      TAG + ', ApiCallGET',
      `apiDebug,response: ${JSON.stringify(response.data)}`
    );
    return response.data;
  } catch (error) {
    ConsoleLogs(
      TAG + ',ApiCallGET', `apiDebug, response error: ${JSON.stringify(error)}`,
    );
    return error;
  }
};
