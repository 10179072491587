import React, { useEffect, useState } from 'react'
import AuthService from '../../../api/services/HomeService';
import LoaderHelper from '../../../Components/Loading/LoaderHelper';
import { alertErrorMessage, alertSuccessMessage } from '../../../Components/CustomAlertMessage';
import Moment from 'react-moment';

const RejectdRequest = () => {
    const [panCard, setpanCard] = useState();


    useEffect(() => {
        listPan()
    }, []);

    const listPan = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.listPan(3).then((result) => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setpanCard(result?.data)
                } catch {
                    alertErrorMessage(result.message);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    };

    const dltPandetails = async (id, userId) => {
        await AuthService.dltPandetails(id, userId).then((result) => {
            if (result?.success) {
                try {
                    alertSuccessMessage("Pan Deleted Successfully!!");
                    listPan()

                } catch {
                    alertErrorMessage(result.message);
                }
            } else {
                alertErrorMessage(result.message);
            }
        });
    };

    return (
        <>
            <div id="layoutSidenav_content">
                <main>
                    <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div className="container-xl px-4">
                            <div className="page-header-content pt-4">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto mt-4">
                                        <h1 className="page-header-title">
                                            <div className="page-header-icon"><i className="ri-group-line"></i></div>
                                            Rejected Pancard Details
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="container-xl px-4 mt-n10">
                        <div className="card mb-4">
                            <div className="card-body">
                                <table id="datatablesSimple">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>UserId</th>
                                            <th>Name</th>
                                            <th>Pan Number</th>
                                            <th>Phone Number</th>
                                            <th>Registration Date</th>
                                            <th>Status</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {panCard ? panCard?.map((data, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{data?.UserId}</td>
                                                    <td>{data?.NameOnPan}</td>
                                                    <td>{data?.PanNumber}</td>
                                                    <td>{data?.data?.mobile_number}</td>
                                                    <td> <Moment date={data?.createdAt} format="YYYY/MM/DD" /> </td>
                                                    <td> <Moment date={data?.createdAt} format="YYYY/MM/DD" /> </td>
                                                    <td>Reject</td>
                                                    <td>
                                                        <div className="table_action_group">
                                                            <button type="button" className="btn badge bg-danger text-white rounded-pill"
                                                                onClick={() => {
                                                                    dltPandetails(data?._id, data?.UserId);
                                                                }}>Delete</button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )

                                        }) : ''}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </main>
            </div>


        </>
    )
}

export default RejectdRequest
