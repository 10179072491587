import React, { useEffect, useState } from 'react'
import AuthService from '../../api/services/HomeService';
import { alertErrorMessage, alertSuccessMessage, alertWarningMessage } from '../../Components/CustomAlertMessage';
import LoaderHelper from '../../Components/Loading/LoaderHelper';
import Swal from 'sweetalert2';
import { useNavigate, useParams } from 'react-router-dom';

const AddContest = () => {
    const { matchId, contestId } = useParams()
    const [winningType, setwinningType] = useState();
    const [winAmount, setwinAmount] = useState();
    const [catogName, setcatogName] = useState('');
    const [adminCommision, setadminCommision] = useState('');
    const [contestSize, setcontestSize] = useState('');
    const [contestType, setcontestType] = useState('Paid');
    const [bonusPercentage, setbonusPercentage] = useState('');
    const [index, setindex] = useState();
    const [categoryList, setcategoryList] = useState();
    const [totalPerc, settotalPerc] = useState();
    const [totalPrice, settotalPrice] = useState();
    const [showContest, setshowContest] = useState(false);
    const [autoCreate, setautoCreate] = useState(false);
    const [confWin, setconfWin] = useState(false);
    const [multTeam, setmultTeam] = useState(false);
    const [multiple_team, setMultiple_team] = useState(1);
    const [calcEntryFee, setCalcEntryFee] = useState('');
    const [shadowID, setShadowID] = useState("");
    const navigate = useNavigate()
    
    const [check, setcheck] = useState([{ StartRank: 1, EndRank: '', PercentageEach: '', Price: '', TotalPercentage: '', TotalPrice: '' }]);

    const handleInput = (e) => {
        switch (e.target.name) {
            case 'contest_price_type':
                setwinningType(e.target.value)
                break;
            case 'winning_amount':
                check[0].amount = +e.target.value
                setwinAmount(e.target.value)
                setcontestType('Paid');
                break;
            case 'category_id':
                setcatogName(e.target.value)
                break;
            case 'entry_fee':
                setCalcEntryFee(e.target.value)
                break;
            case 'admin_comission':
                setadminCommision(e.target.value)
                setcontestType('Paid');
                break;
            case 'contest_size':
                setcontestSize(e.target.value)
                check[0].contestSize = +e.target.value
                break;
            case 'multiple_team':
                setMultiple_team(e.target.value);
                if (e.target.value <= 1) {
                    alertWarningMessage('Team size should be grater then 1');
                }
                break;
            case 'contest_type':
                if (e.target.value === 'Free') {
                    setwinAmount('');
                    setadminCommision('');
                    setbonusPercentage('')
                    setcontestType(e.target.value);
                } else {
                    setcontestType('Paid');

                }
                break;

            case 'usable_bonus_percentage':
                setbonusPercentage(e.target.value)
                setcontestType('Paid');
                break;

            default:
                break;
        }
    };

    const handleReset = () => {
        setcheck([{ StartRank: 1, EndRank: '', PercentageEach: '', Price: '', TotalPercentage: '', TotalPrice: '' }])
        setwinningType('');
        setwinAmount('');
        setcatogName('');
        setadminCommision('');
        setcontestSize('');
        setcontestType('');
        setbonusPercentage('');
    }

    const addContest = async (winningType, winAmount, catogName, adminCommision, contestSize, contestType, calcEntryFee, bonusPercentage, uniqueArr2, autoCreate, multTeam, confWin, multiple_team) => {
        await Swal.fire({
            title: "Click 'Confirm' to create contest?",
            icon: 'info',
            showDenyButton: true,
            confirmButtonText: 'Confirm',
            denyButtonText: `Cancel`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                if (!matchId?.includes("AddNew")) {
                    addDynamicContest(winningType, winAmount, catogName, adminCommision, contestSize, contestType, calcEntryFee, bonusPercentage, uniqueArr2, autoCreate, multTeam, confWin, multiple_team)
                    return
                }
                LoaderHelper.loaderStatus(true);
                try {
                    const result = await AuthService.addContest(winningType, winAmount, catogName, adminCommision, contestSize, contestType, calcEntryFee, bonusPercentage, uniqueArr2, autoCreate, multTeam, confWin, multiple_team)
                    LoaderHelper.loaderStatus(false);
                    if (result?.success) {
                        alertSuccessMessage('Contest Added Successfully!!');
                        window.location.reload()
                        handleReset()
                        setshowContest(false)

                    } else {
                        alertWarningMessage(result?.message);
                    }
                } catch (error) {
                    LoaderHelper.loaderStatus(false);
                    alertWarningMessage(error);
                }

            }
        })
    };

    const addDynamicContest = async (winningType, winAmount, catogName, adminCommision, contestSize, contestType, calcEntryFee, bonusPercentage, uniqueArr2, autoCreate, multTeam, confWin, multiple_team) => {
        try {
            const result = await AuthService.EditLiveMatches(winningType, winAmount, catogName, adminCommision, contestSize, contestType, calcEntryFee, bonusPercentage, uniqueArr2, autoCreate, multTeam, confWin, multiple_team, shadowID)
            LoaderHelper.loaderStatus(false);
            if (result?.success) {
                alertSuccessMessage('Contest updated Successfully!!');
                navigate(`/ContestDetails/${matchId}`)
                handleReset()
                setshowContest(false)

            } else {
                alertWarningMessage(result?.message);
            }
        } catch (error) {
            LoaderHelper.loaderStatus(false);
            alertWarningMessage(error);
        }
    }

    const handlecatogryList = async () => {
        try {
            const result = await AuthService.getCategorylist()
            if (result?.success) {
                setcategoryList(result?.data?.reverse())
            }
        } catch (error) {
            alertErrorMessage(error);
        }
    };


    const addField = (index) => {
        let length = check.length - 1
        if (!check[length]['EndRank'] || !check[length]['Price']) {
            alertWarningMessage('Please fill the row first')
        }
        else if (totalPrice >= winAmount) {
            alertWarningMessage('You can not add one more row')
        }
        else if (+check[length]['EndRank'] === +contestSize) {
            alertWarningMessage('Contest Rank already filled')
        }
        else {
            setcheck((prev) => [...prev, { 'StartRank': +check[length]['EndRank'] + 1, 'EndRank': '', 'PercentageEach': '', 'Price': '', 'TotalPercentage': '', 'TotalPrice': '' }])
        }
    };

    const checkEndRank = (index) => {
        let temp = [...check];
        if (check[index]['EndRank'] < check[index]['StartRank']) {
            temp[index]['EndRank'] = '';
            alertWarningMessage('End Rank should be greater then Start Rank')
            setcheck(temp)
        }
        else if (check[index]['EndRank'] > contestSize) {
            temp[index]['EndRank'] = '';
            alertWarningMessage('Rank should not be greater then Contest Size')
            setcheck(temp)
        }
    };

    const handleRankInput = (e, index) => {
        setindex(index)
        let temp = [...check];
        let field = e.target.name
        switch (e.target.name) {
            case 'StartRank':
                temp[index]['StartRank'] = +e.target.value
                break;

            case 'EndRank':
                temp[index][field] = +e.target.value
                if (+e.target.value > +contestSize) {
                    alertWarningMessage('Rank should not be greater then Contest Size')
                    temp[index][field] = ''
                    temp[index]['TotalPercentage'] = 0
                    temp[index]['TotalPrice'] = 0
                    temp[index]['Price'] = 0
                    temp[index]['PercentageEach'] = 0
                } else {
                    temp[index][field] = +e.target.value
                    temp[index]['TotalPercentage'] = 0
                    temp[index]['TotalPrice'] = 0
                    temp[index]['Price'] = 0
                    temp[index]['PercentageEach'] = 0
                }
                break;
            case 'Price':
                temp[index][field] = +e.target.value
                if (+totalPrice > winAmount) {
                    temp[index][field] = ''
                    temp[index]['TotalPercentage'] = 0
                    temp[index]['PercentageEach'] = 0
                    temp[index]['TotalPrice'] = 0
                    temp[index]['Price'] = 0
                } else {
                    temp[index][field] = +e.target.value
                    temp[index]['PercentageEach'] = ((+e.target.value / +winAmount) * 100).toFixed(2)
                    temp[index]['TotalPercentage'] = ((+check[index]['EndRank'] - +check[index]['StartRank'] + 1) * +temp[index]['PercentageEach']).toFixed(2)
                    temp[index]['TotalPrice'] = ((+check[index]['EndRank'] - +check[index]['StartRank'] + 1) * +e.target.value)
                }
                break;

            default:
                break;
        }
        setcheck(temp)

    };

    const deleteRow = (data) => {
        let filterd = check.filter((item) => {
            return item !== data
        })
        setcheck(filterd);
    };

    const showContestpage = () => {
        if (multTeam && multiple_team <= 1) {
            alertWarningMessage('Team size should be grater then 1');
            return
        } else if (contestType && winAmount && catogName && adminCommision && contestSize && calcEntryFee && winningType) {
            setshowContest(true)
        }
        else {
            alertWarningMessage('Please fill all required Details')
        };
    };


    const getAdconMtch = async () => {
        try {
            LoaderHelper.loaderStatus(true);
            const result = await AuthService.getAdconMtch(matchId)
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                if (result?.data?.length > 0) {
                    let filteredArr = result?.data?.filter((data) => data?.contest_category_details?._id === contestId)[0]
                    if (!filteredArr) {
                        alertErrorMessage("Error occured")
                        navigate(`/ContestDetails/${matchId}`)
                        return
                    }
                    setcheck(filteredArr?.contest_category_details?.Rankdata);
                    setShadowID(filteredArr?.shadow_contest_id);

                    let contestDetails = filteredArr?.contest_category_details;
                    setwinningType(contestDetails?.ContestType);
                    setwinAmount(contestDetails?.WinningAmount);
                    setcatogName(contestDetails?.categoryName);
                    setadminCommision(contestDetails?.AdminCommission);
                    setcontestSize(contestDetails?.Contestsize);
                    setcontestType(contestDetails?.EnteryType);
                    setCalcEntryFee(contestDetails?.EnteryFee);
                    setbonusPercentage(contestDetails?.UsableBonusPercantage);
                }
            }
        } catch (error) {
            alertErrorMessage(error.message);
        }
    };

    useEffect(() => {
        const totalPerc = check.reduce((acc, curr) => {
            return acc + (+curr?.TotalPercentage || 0);
        }, 0);
        settotalPerc(totalPerc)
        const totalPrice = check.reduce((acc, curr) => {
            return acc + (+curr?.TotalPrice || 0);
        }, 0);
        settotalPrice(totalPrice)
        if (totalPrice > winAmount) {
            alertWarningMessage('Total Price must not greater then Total Winning Amount')
        }

    }, [check]);

    useEffect(() => {
        if (!matchId?.includes("AddNew")) {
            getAdconMtch()

        }
    }, [matchId]);


    useEffect(() => {
        handlecatogryList()
    }, []);

    return (
        <>

            <div id="layoutSidenav_content">
                <main>
                    <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div className="container-xl px-4">
                            <div className="page-header-content pt-4">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto mt-4">
                                        <h1 className="page-header-title">
                                            <div className="page-header-icon"><i className="ri-trophy-line"></i></div>
                                            Add Contest
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    {/* <!-- Main page content--> */}
                    <div className="container-xl px-4 mt-n10" style={{ display: !showContest ? '' : 'none' }}>
                        <div className="card mb-4">
                            <div className="card-header">
                                <h3 className="card-title mb-0">Enter Details</h3> </div>
                            <form enctype="" method="post" acceptCharset="utf-8" validaterset="validaterset" id="subAdminForm" action="">
                                <div className="card-body">

                                    <div className="row">
                                        <div className="col-xs-6 col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <div className="input select">
                                                    <label for="contest-price-type">Contest Type <span className="required">*</span></label>
                                                    <select name="contest_price_type" className="form-control form-select contest_price_type" id="contest-price-type" value={winningType} onChange={(e) => { handleInput(e) }} >

                                                        <option selected disabled>Select Type</option>
                                                        <option value="Classic">Classic</option>
                                                        <option value="Second Inning">Second Inning</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-6 col-sm-6 col-md-6 winning_amount_div">
                                            <div className="form-group">
                                                <div className="input text">
                                                    <label for="winning-amount">Winning amount (INR) <span className="required">*</span></label>
                                                    <input type="number" onWheel={(e) => e.target.blur()} name="winning_amount" className="form-control winning_amount" placeholder="Winning amount" id="winning-amount"
                                                        value={winAmount} onChange={(e) => { handleInput(e) }} />
                                                </div> <small>Maximum winning amount rs. ----.</small> </div>
                                        </div>
                                        <div className="col-xs-6 col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <label for="category_id">Category name <span className="required">*</span></label>
                                                <div className="input select">
                                                    <select name="category_id" className="form-control form-select category" id="category_id" onChange={(e) => { handleInput(e) }} value={catogName} >
                                                        <option value="" selected disabled>Select Category</option>
                                                        {categoryList ? categoryList?.map((data) => {
                                                            return (
                                                                <option value={data?.categoryName}>{data?.categoryName}</option>
                                                            )
                                                        }) : ""}

                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-6 col-sm-6 col-md-6" >
                                            <div className="form-group">
                                                <div className="input text">
                                                    <label for="admin-comission">Admin Commission (%)<span className="required">*</span></label>
                                                    <input type="number" onWheel={(e) => e.target.blur()} name="admin_comission" className="form-control adminC" placeholder="Admin Commission" maxlength="5" id="admin-comission" onChange={(e) => { handleInput(e) }} value={adminCommision} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-6 col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <div className="input text">
                                                    <label for="contest-size">Contest size (Team size) <span className="required">*</span></label>
                                                    <input type="number" onWheel={(e) => e.target.blur()} name="contest_size" className="form-control contest_size" placeholder="Contest size" maxlength="10" id="contest-size" value={contestSize} onChange={(e) => { handleInput(e) }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-6 col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <div className="input select">
                                                    <label for="contest-type">Entry Type <span className="required">*</span></label>
                                                    <select name="contest_type" className="form-control form-select" id="contest-type" value={contestType} onChange={(e) => { handleInput(e) }}>

                                                        <option value="Free">Free</option>
                                                        <option selected value="Paid">Paid</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-6 col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <div className="input text">
                                                    <label for="entry-fee">Entry fee (INR) <span className="required">*</span></label>
                                                    <input type="number" name="entry_fee" className="form-control entry_fee" placeholder="Entry fee" maxlength="15" id="entry-fee" value={calcEntryFee}
                                                        onChange={(e) => { handleInput(e) }} onWheel={(e) => e.target.blur()} />
                                                </div> <small>Maximum entry fee rs. ---.</small> </div>
                                        </div>
                                        <div className="col-xs-6 col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <div className="input number">
                                                    <label for="usable-bonus-percentage">Usable Bonus Percentage (0-100)<span className="required">*</span></label>
                                                    <input type="number" onWheel={(e) => e.target.blur()} name="usable_bonus_percentage" className="form-control" placeholder="Usable Bonus Percentage" id="usable-bonus-percentage" onChange={(e) => { handleInput(e) }} value={bonusPercentage} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-md-12">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="confirmed_winning" onChange={(e) => { setconfWin(e.target.checked ? true : false) }} />
                                                <label className="form-check-label" for="confirmed_winning">
                                                    Confirmed winning even if the contest remains unfilled.
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="multiple_team" onChange={(e) => { setmultTeam(e.target.checked ? true : false); e.target.checked ? setMultiple_team(2) : setMultiple_team(1) }} />
                                                <label className="form-check-label" for="multiple_team">
                                                    Join with multiple teams.
                                                </label>
                                            </div>
                                            {multTeam && <div className="form-check col-xs-4 col-sm-4 col-md-4">
                                                <label for="usable-bonus-percentage">Enter Team Size<span className="required">*</span></label>
                                                <input type="number" onWheel={(e) => e.target.blur()} name="multiple_team" className="form-control" placeholder="Team" id="multiple_team" onChange={(e) => { handleInput(e) }} value={multiple_team} />
                                            </div>
                                            }
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="auto_create" onChange={(e) => { setautoCreate(e.target.checked ? true : false) }} />
                                                <label className="form-check-label" for="auto_create">
                                                    Auto create
                                                </label>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    {contestType === 'Free' ? <button type="button" className="btn btn-primary submit px-5" onClick={() => { addContest(winningType, winAmount, catogName, adminCommision, contestSize, contestType, calcEntryFee, bonusPercentage, check, autoCreate, multTeam, confWin, multiple_team) }}>Next</button> :
                                        <button type="button" className="btn btn-primary submit px-5" onClick={() => showContestpage()}>Next</button>}
                                </div>
                            </form>
                        </div>
                    </div>


                    {/* Contest price breakdown starts here..... */}
                    <div className="container-xl px-4 mt-n10" style={{ display: showContest ? '' : 'none' }}>
                        <div className="card mb-4">
                            <div className="card-body">
                                <span style={{ cursor: 'pointer' }} onClick={() => { setshowContest(false) }}><i className="fa fa-chevron-left"></i>  Back</span>
                                <div className='d-flex justify-content-evenly mb-5 '>
                                    <h3>Winning amount:{winAmount}</h3>
                                    <h3>Contest Size:{contestSize}</h3>
                                    <h3>Entery Fee:{calcEntryFee}</h3>
                                </div>
                                <div className=' ' >
                                    <div className='row' >
                                        <div className='col-md-2'> <h6>Start Rank</h6> </div>
                                        <div className='col-md-2'><h6>End Rank</h6></div>
                                        <div className='col-md-2'><h6>Price Each</h6></div>
                                        <div className='col-md-2'><h6>Percentage Each</h6></div>
                                        <div className='col-md-2'><h6>Total Percentage ({totalPerc})</h6></div>
                                        <div className='col-md-2'> <h6>Total Price ({totalPrice})</h6></div>
                                    </div>
                                    <hr />
                                </div>
                                {console.log(check, 'check')}
                                {check && check.map((data, index) => {
                                    return (
                                        <><div key={index} className='row '>
                                            <div className='col-md-2' ><input type='number' className='form-control form-control-solid' placeholder='StartRank' name='StartRank' value={data.StartRank} disabled /> </div>

                                            <div className='col-md-2' ><input type='number' className='form-control form-control-solid' placeholder='EndRank' name='EndRank' onChange={(e) => { handleRankInput(e, index) }} onBlur={() => { checkEndRank(index) }} value={data.EndRank} /></div>

                                            <div className='col-md-2' ><input className='form-control form-control-solid' placeholder='Price' name='Price' disabled={data?.EndRank ? false : true} onChange={(e) => { handleRankInput(e, index) }} value={data.Price} /></div>

                                            <div className='col-md-2' ><input type='number' disabled className='form-control form-control-solid' placeholder='PercentageEach' name='PercentageEach' onChange={(e) => { handleRankInput(e, index) }} value={+data.PercentageEach} /></div>

                                            <div className='col-md-2' ><input type='number' className='form-control form-control-solid' placeholder='TotalPercentage' name='TotalPercentage' value={data.TotalPercentage} disabled /></div>

                                            <div className='col-md-2' >
                                                <div className='d-flex align-items-center' >
                                                    <input className='form-control form-control-solid' placeholder='TotalPrice' name='TotalPrice' value={data.TotalPrice} disabled />
                                                    <button className="btn btn-danger ms-2 " disabled={index === 0 ? true : false} type="button" onClick={() => { deleteRow(data) }}> <i className="ri-delete-bin-line"></i> </button>
                                                </div>
                                            </div>

                                        </div>
                                            <hr />
                                        </>
                                    )
                                })}
                                <div className=' d-flex justify-content-end' >
                                    <button className="btn btn-success btn-block btn-sm px-4 w-40 mt-1" onClick={() => { addField(index) }}>Add row</button>
                                </div>

                                <div className='d-flex justify-content-start'>
                                    <button className="btn btn-primary btn-block w-30 mt-3 " disabled={totalPrice > winAmount ? true : false} type="button" onClick={() => { addContest(winningType, winAmount, catogName, adminCommision, contestSize, contestType, calcEntryFee, bonusPercentage, check, autoCreate, multTeam, confWin, multiple_team) }}>Submit Details</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}


export default AddContest
