import React, { useEffect, useState } from 'react'
import AuthService from '../../api/services/HomeService';
import Moment from 'react-moment';
import { usePagination, useSortBy, useTable } from 'react-table';
import { createChart } from '../../utils/GenerateCharts';


const Dashboard = () => {
    const [subAdminLogin, setsubAdminLogin] = useState([]);
    const [dashboardData, setDashboardData] = useState({});

    const UserList = async () => {
        try {
            const result = await AuthService.UserList()
            if (result?.success) {
                await createChart(result?.data, "user-registration-chart", "Users", "User Registration")
            }
        } catch {
            return
        }
    };

    const transactionList = async () => {
        try {
            const result = await AuthService.getTransaction()
            if (result?.success) {
                createChart(result?.data, "user-transaction-chart", "Transactions", "User Transactions")
            }
        } catch {
            return
        }
    };


    const getDashboardCounts = async () => {
        try {
            const result = await AuthService.dashboardData()
            if (result?.success) {
                setDashboardData(result?.data)
            }
        } catch (error) {
            console.log(error?.message);
        }
    };


    const AdminLogin = async () => {
        try {
            const result = await AuthService.logs();
            if (result?.success) {
                setsubAdminLogin(result?.data)
            }
        } catch (error) {
            console.log(error?.message);
        }
    };

    useEffect(() => {
        getDashboardCounts()
        UserList()
        transactionList()
        AdminLogin()
    }, []);

    // ******* React Table Implementation SubAdmin History *********** //
    const data = React.useMemo(() => subAdminLogin, [subAdminLogin]);
    const columns = React.useMemo(() => [
        { Header: "Sr No.", accessor: "_id", disableSortBy: true, Cell: ({ row }) => { const { index } = row; return index + 1 } },
        { Header: "Date/Time", accessor: "date", disableSortBy: true, Cell: ({ row }) => { return <Moment date={row?.values?.date} format="DD/MM/YYYY: h:mm A" /> } },
        { Header: "Email", accessor: "Email", disableSortBy: true, },
        { Header: "Name", accessor: "firstName", disableSortBy: true, Cell: ({ row }) => { return row?.original?.firstName + ' ' + row?.original?.lastName } },

    ], []);

    const { getTableProps, getTableBodyProps, headerGroups, page, nextPage, previousPage, canNextPage, canPreviousPage, gotoPage, pageCount, prepareRow } = useTable({ columns, data }, useSortBy, usePagination);

    return (
        <>
            <div id="layoutSidenav_content">
                <main>
                    <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div className="container-xl px-4">
                            <div className="page-header-content pt-4">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto mt-4">
                                        <h1 className="page-header-title">
                                            <div className="page-header-icon"><i data-feather="grid"></i></div>
                                            Dashboard
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="container-xl px-4 mt-8">
                        <div className="row">
                            <div className="col-lg-5 col-xl-5 mb-4 mx-5">
                                <canvas id="user-registration-chart" width={400} height={200}></canvas>

                            </div>
                            <div className="col-lg-5 col-xl-5 mb-4 mx-5">
                                <canvas id="user-transaction-chart" width={400} height={200}></canvas>
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-lg-6 col-xl-3 mb-4">
                                <div className="card bg-primary text-white h-100">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="me-3">
                                                <div className="text-white-75 small">Total Users</div>
                                                <div className="fs-1 fw-bold text-capitalize">{dashboardData?.totUsers}</div>
                                            </div>
                                            <i className="ri-xxl text-white-50 ri-group-line"></i>
                                        </div>
                                    </div>
                                    <div className="card-footer d-flex align-items-center justify-content-between small">
                                        <a className="text-white stretched-link" href="/UsersList">View All</a>
                                        <div className="text-white"><i className="fas fa-angle-right"></i></div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-xl-3 mb-4">
                                <div className="card bg-warning text-white h-100">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="me-3">
                                                <div className="text-white-75 small">Today Total Deposit</div>
                                                <div className="fs-1 fw-bold text-capitalize">{dashboardData?.TodayTotDep}</div>
                                            </div>
                                            <i className="ri-xxl text-white-50 ri-wallet-3-line"></i>
                                        </div>
                                    </div>
                                    <div className="card-footer d-flex align-items-center justify-content-between small">
                                        <a className="text-white stretched-link" href="#!">View All</a>
                                        <div className="text-white"><i className="fas fa-angle-right"></i></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-3 mb-4">
                                <div className="card bg-success text-white h-100">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="me-3">
                                                <div className="text-white-75 small">Current Month Total Deposit</div>
                                                <div className="fs-1 fw-bold text-capitalize">{dashboardData?.MonthTotDep}</div>
                                            </div>
                                            <i className="ri-xxl text-white-50 ri-wallet-3-line"></i>
                                        </div>
                                    </div>
                                    <div className="card-footer d-flex align-items-center justify-content-between small">
                                        <a className="text-white stretched-link" href="#!">View All</a>
                                        <div className="text-white"><i className="fas fa-angle-right"></i></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-3 mb-4">
                                <div className="card bg-danger text-white h-100">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="me-3">
                                                <div className="text-white-75 small">Current Month Total Withdrawal</div>
                                                <div className="fs-1 fw-bold text-capitalize">{dashboardData?.MonthTotWith}</div>
                                            </div>
                                            <i className="ri-xxl text-white-50 ri-wallet-3-line"></i>
                                        </div>
                                    </div>
                                    <div className="card-footer d-flex align-items-center justify-content-between small">
                                        <a className="text-white stretched-link" href="/WithdrawConfirmed">View All</a>
                                        <div className="text-white"><i className="fas fa-angle-right"></i></div>
                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-6 col-xl-3 mb-4">
                                <div className="card bg-danger text-white h-100">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="me-3">
                                                <div className="text-white-75 small">Today User Count</div>
                                                <div className="fs-1 fw-bold text-capitalize">{dashboardData?.todayUserCount}</div>
                                            </div>
                                            <i className="ri-xxl text-white-50 ri-trophy-line"></i>
                                        </div>
                                    </div>
                                    <div className="card-footer d-flex align-items-center justify-content-between small">
                                        <a className="text-white stretched-link" href="#?">View All</a>
                                        <div className="text-white"><i className="fas fa-angle-right"></i></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-3 mb-4">
                                <div className="card bg-success text-white h-100">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="me-3">
                                                <div className="text-white-75 small ">Total Active Contest</div>
                                                <div className="fs-1 fw-bold text-capitalize">{dashboardData?.contestActive}</div>
                                            </div>
                                            <i className="ri-xxl text-white-50 ri-trophy-line"></i>
                                        </div>
                                    </div>
                                    <div className="card-footer d-flex align-items-center justify-content-between small">
                                        <a className="text-white stretched-link" href="/ContestList">View All</a>
                                        <div className="text-white"><i className="fas fa-angle-right"></i></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-3 mb-4">
                                <div className="card bg-warning text-white h-100">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="me-3">
                                                <div className="text-white-75 small">Total Inactive Contest</div>
                                                <div className="fs-1 fw-bold text-capitalize">{dashboardData?.contestInActive}</div>
                                            </div>
                                            <i className="ri-xxl text-white-50 ri-trophy-line"></i>
                                        </div>
                                    </div>
                                    <div className="card-footer d-flex align-items-center justify-content-between small">
                                        <a className="text-white stretched-link" href="/ContestList">View All</a>
                                        <div className="text-white"><i className="fas fa-angle-right"></i></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-3 mb-4">
                                <div className="card bg-primary text-white h-100">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="me-3">
                                                <div className="text-white-75 small">Today Joined Contest</div>
                                                <div className="fs-1 fw-bold text-capitalize">{dashboardData?.todayMatchContestCount}</div>
                                            </div>
                                            <i className="ri-xxl text-white-50 ri-trophy-line"></i>
                                        </div>
                                    </div>
                                    <div className="card-footer d-flex align-items-center justify-content-between small">
                                        <a className="text-white stretched-link" href="#!">View All</a>
                                        <div className="text-white"><i className="fas fa-angle-right"></i></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-3 mb-4">
                                <div className="card bg-primary text-white h-100">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="me-3">
                                                <div className="text-white-75 small">Total Active Player</div>
                                                <div className="fs-1 fw-bold text-capitalize">{dashboardData?.userActive}</div>
                                            </div>
                                            <i className="ri-xxl text-white-50 ri-user-6-fill"></i>
                                        </div>
                                    </div>
                                    <div className="card-footer d-flex align-items-center justify-content-between small">
                                        <a className="text-white stretched-link" href="/UsersList">View All</a>
                                        <div className="text-white"><i className="fas fa-angle-right"></i></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-3 mb-4">
                                <div className="card bg-warning text-white h-100">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="me-3">
                                                <div className="text-white-75 small">Unverified Users</div>
                                                <div className="fs-1 fw-bold text-capitalize">{dashboardData?.unverifyUser}</div>
                                            </div>
                                            <i className="ri-xxl text-white-50 ri-user-6-fill"></i>
                                        </div>
                                    </div>
                                    <div className="card-footer d-flex align-items-center justify-content-between small">
                                        <a className="text-white stretched-link" href="/UsersList">View All</a>
                                        <div className="text-white"><i className="fas fa-angle-right"></i></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-3 mb-4">
                                <div className="card bg-success text-white h-100">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="me-3">
                                                <div className="text-white-75 small">New Registration</div>
                                                <div className="fs-1 fw-bold text-capitalize">{dashboardData?.todayUserCount}</div>
                                            </div>
                                            <i className="ri-xxl text-white-50 ri-user-add-line"></i>
                                        </div>
                                    </div>
                                    <div className="card-footer d-flex align-items-center justify-content-between small">
                                        <a className="text-white stretched-link" href="#!">View All</a>
                                        <div className="text-white"><i className="fas fa-angle-right"></i></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-3 mb-4">
                                <div className="card bg-danger text-white h-100">
                                    <div className="card-body">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="me-3">
                                                <div className="text-white-75 small">Today total withdrawal</div>
                                                <div className="fs-1 fw-bold text-capitalize">{dashboardData?.TodayTotWith}</div>
                                            </div>
                                            <i className="ri-xxl text-white-50 ri-wallet-3-line"></i>
                                        </div>
                                    </div>
                                    <div className="card-footer d-flex align-items-center justify-content-between small">
                                        <a className="text-white stretched-link" href="/WithdrawPending">View All</a>
                                        <div className="text-white"><i className="fas fa-angle-right"></i></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                       {/* <div className="card mb-4">
                            <div className="card-header"  >
                                <h4 className="mb-0" >
                                    SubAdmin Last Login
                                </h4>
                            </div>
                            <div className="card-body" >
                                <table width="100%" className='react_table' {...getTableProps()}>
                                    <thead  >
                                        {headerGroups.map((headerGroup, index) => (
                                            <tr key={index} {...headerGroup.getHeaderGroupProps()} >
                                                {headerGroup.headers.map((column, index) => (
                                                    <th key={index} {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                        {column.render("Header")}
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                        {page.map((row, index) => {
                                            prepareRow(row);
                                            return (
                                                <tr key={index} {...row.getRowProps()}>
                                                    {row.cells.map((cell, index) => (
                                                        <td key={index} {...cell.getCellProps()} >{cell.render("Cell")} </td>
                                                    ))}
                                                </tr>
                                            );
                                        })}
                                        {subAdminLogin?.length === 0 && <tr rowSpan="5">
                                            <td colSpan="12">
                                                <p className="text-center" >
                                                    No data Available
                                                </p>
                                            </td>
                                        </tr>}
                                    </tbody>
                                </table>
                                {subAdminLogin?.length !== 0 &&
                                    <div className="col-md-4" >
                                        <div className="btn-group btn-group-mini justify-content-end " role="group" aria-label="Basic radio toggle button group">
                                            <input type="button" className="btn-check" name="btnradio" id="btnradio125" autoComplete="off" />
                                            <label className="btn " htmlFor="btnradio125" disabled={!canPreviousPage} onClick={() => { gotoPage(0) }} >{'<<'}</label>

                                            <input type="button" className="btn-check" name="btnradio" id="btnradio250" autoComplete="off" />
                                            <label className="btn " htmlFor="btnradio250" disabled={!canPreviousPage} onClick={() => { previousPage() }}>Previous</label>

                                            <input type="button" className="btn-check" name="btnradio" id="btnradio375" autoComplete="off" />
                                            <label className="btn " htmlFor="btnradio375" disabled onClick={() => { nextPage() }}>Next</label>
                                            <input type="button" className="btn-check" name="btnradio" id="btnradio3100" autoComplete="off" />
                                            <label className="btn " htmlFor="btnradio3100" disabled={!canNextPage} onClick={() => { gotoPage(pageCount - 1) }}>{'>>'}</label>
                                        </div>
                                    </div>}
                            </div>
                        </div> */}
                    </div>

                </main>
            </div>
        </>
    )
}

export default Dashboard
