import React from 'react'
import Header from '../Header'
import Sidebar from '../Sidebar'

const TDS = () => {
  return (
<>

    <div id="layoutSidenav_content">
        <main>
            <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                <div className="container-xl px-4">
                    <div className="page-header-content pt-4">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-auto mt-4">
                                <h1 className="page-header-title">
                                    <div className="page-header-icon"><i className="ri-brackets-fill"></i></div>
                                  TDS List
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {/* <!-- Main page content-->
            <!-- Example Colored Cards for Dashboard Demo--> */}
            <div className="container-xl px-4 mt-n10">

                <div className="card mb-4">
                           
                    <div className="card-body">
                        <table id="datatablesSimple">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Match</th>
                                    <th>Match Date</th>
                                    <th>User Mobile  </th>
                                    <th>User Email</th>
                                    <th> Totoal Amount INR</th>
                                    <th>TDS Amount INR</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th>#</th>
                                    <th>Match</th>
                                    <th>Match Date</th>
                                    <th>User Mobile  </th>
                                    <th>User Email</th>
                                    <th> Totoal Amount INR</th>
                                    <th>TDS Amount INR</th>
                                    <th>Date</th>
                                </tr>
                            </tfoot>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>


                
           
        </main>
    </div>
</>
  )
}

export default TDS
